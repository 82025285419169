@charset "utf-8";
/*base*/

$small: "only screen and (max-width:740px)";//740px under
$middle:  "(min-width:981px)";//740 over
$large:  "(min-width:1201px)";//1200 over

@import url(//fonts.googleapis.com/css?family=Oswald:400,700);
$FontFamily: "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic,"メイリオ", Meiryo, Sans-Serif;

$FontFamilyT: "秀英丸ゴシック B","Shuei MaruGo B","メイリオ", "Meiryo", verdana, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif;

$imgPath: "../images/";

$accent-color: #2caa7f;
$sub_color: #675f61;
$point_color: #ff8f09;

$font_color: #222222;
/*font-size*/

$fontSizeList: 58%, 65%, 72%, 79%, 86%, 93%, 100%, 107%, 115%, 122%, 129%, 136%, 143%, 150%, 157%, 164%, 172%, 179%, 186%, 193%, 200%, 207%, 214%, 220%, 227%, 234%, 241%, 248%, 255%, 262%, 269%, 278%, 285%;

@function fz($size) {
	@if $size < 8 {
		$size: 8;
	}
	@if $size > 40 {
		$size: 40;
	}
	@return nth($fontSizeList, $size - 7);
}
/*line-height*/

$lineSizeList: 0.6em, 0.7em, 0.8em, 0.9em, 1em, 1.1em, 1.2em, 1.3em, 1.4em, 1.5em, 1.6em, 1.7em, 1.8em, 1.9em, 2.0em, 2.1em, 2.2em, 2.3em, 2.4em, 2.5em, 2.6em;

@function lz($size) {
	@if $size < 6 {
		$size: 6;
	}
	@if $size > 26 {
		$size: 26;
	}
	@return nth($fontSizeList, $size - 3);
}
/*box-shadow*/
@mixin box_shadow($value: 0px 0px 10px #88aa88) {
	-webkit-box-shadow: $value;
	   -moz-box-shadow: $value;
		-ms-box-shadow: $value;
			box-shadow: $value;
}
/*link-color*/
@mixin linkcolor($color) {
	 color: $color;

	 &:hover, &:active, &:focus {
		 color: lighten($color, 20%);
	 }
}
@mixin btlinkcolor($color){
		&:hover, &:active, &:focus{
			background: lighten($color,10%);
		}
}
%imglink{
	   -webkit-transition: all .3s;
		transition: all .3s;
		opacity: 1;
		&:hover, &:active, &:focus{
			opacity: 0.6;
		}
}
a{
	 @include linkcolor(#224488);
}
%normal-link{
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}
%width-min{
	min-width: 980px;
}
%width-fit{
	@extend %width-min;
	max-width: 1200px;
	margin: 0 auto;
}
.clearfix{
	*zoom: 1;
	&:after {
	  content: "\0020";
	  display: block;
	  height: 0;
	  clear: both;
	  overflow: hidden;
	  visibility: hidden;
	}
}
/*gridbase*/////////////////////////////////
.row{
	margin-left: -10px;
	margin-right: -10px;
	@extend .clearfix;
}
.col-xs-1 ,.col-xs-2 ,.col-xs-3 ,.col-xs-4 ,.col-xs-5 ,.col-xs-6 ,.col-xs-7 ,.col-xs-8 ,.col-xs-9 ,.col-xs-10 ,.col-xs-11 ,.col-xs-12,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10 ,.col-sm-11 ,.col-sm-12,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10 ,.col-md-11 ,.col-md-12,.col-lg-1 ,.col-lg-2 ,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,{
	float: left;
	position: relative;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
}
/*base*/
.col-xs-1 {
	width: 8.33333%;
}

.col-xs-2 {
	width: 16.66667%;
}

.col-xs-3 {
	width: 25%;
}

.col-xs-4 {
	width: 33.33333%;
}

.col-xs-5 {
	width: 41.66667%;
}

.col-xs-6 {
	width: 50%;
}

.col-xs-7 {
	width: 58.33333%;
}

.col-xs-8 {
	width: 66.66667%;
}

.col-xs-9 {
	width: 75%;
}

.col-xs-10 {
	width: 83.33333%;
}

.col-xs-11 {
	width: 91.66667%;
}

.col-xs-12 {
	width: 100%;
}



/*540*/

.col-sm-1{
	@media (min-width: 540px){
		width: 8.33333%;
	}
}

.col-sm-2{
	@media (min-width: 540px){
		width: 16.66667%;
	}
}

.col-sm-3{
	@media (min-width: 540px){
		width: 25%;
	}
}

.col-sm-4{
	@media (min-width: 540px){
		width: 33.33333%;
	}
}

.col-sm-5{
	@media (min-width: 540px){
		width: 41.66667%;
	}
}

.col-sm-6{
	@media (min-width: 540px){
		width: 50%;
	}
}

.col-sm-7{
	@media (min-width: 540px){
		width: 58.33333%;
	}
}

.col-sm-8{
	@media (min-width: 540px){
		width: 66.66667%;
	}
}

.col-sm-9{
	@media (min-width: 540px){
		width: 75%;
	}
}

.col-sm-10 {
	@media (min-width: 540px){
		width: 83.33333%;
	}
}

.col-sm-11 {
	@media (min-width: 540px){
		width: 91.66667%;
	}
}

.col-sm-12{
	@media (min-width: 540px){
		width: 100%;
	}
}

/*740*/
.col-md-1{
	@media (min-width: 740px){
		width: 8.33333%;
	}
}

.col-md-2{
	@media (min-width: 740px){
		width: 16.66667%;
	}
}

.col-md-3{
	@media (min-width: 740px){
		width: 25%;
	}
}

.col-md-4{
	@media (min-width: 740px){
		width: 33.33333%;
	}
}

.col-md-5{
	@media (min-width: 740px){
		width: 41.66667%;
	}
}

.col-md-6{
	@media (min-width: 740px){
		width: 50%;
	}
}

.col-md-7{
	@media (min-width: 740px){
		width: 58.33333%;
	}
}

.col-md-8{
	@media (min-width: 740px){
		width: 66.66667%;
	}
}

.col-md-9{
	@media (min-width: 740px){
		width: 75%;
	}
}

.col-md-10 {
	@media (min-width: 740px){
		width: 83.33333%;
	}
}

.col-md-11 {
	@media (min-width: 740px){
		width: 91.66667%;
	}
}

.col-md-12{
	@media (min-width: 740px){
		width: 100%;
	}
}

/*1000*/
.col-lg-1 {
	@media (min-width: 1000px){
		width: 8.33333%;
	}
}

.col-lg-2 {
	@media (min-width: 1000px){
		width: 16.66667%;
	}
}

.col-lg-3{
	@media (min-width: 1000px){
		width: 25%;
	}
}

.col-lg-4{
	@media (min-width: 1000px){
		width: 33.33333%;
	}
}

.col-lg-5{
	@media (min-width: 1000px){
		width: 41.66667%;
	}
}

.col-lg-6{
	@media (min-width: 1000px){
		width: 50%;
	}
}

.col-lg-7{
	@media (min-width: 1000px){
		width: 58.33333%;
	}
}

.col-lg-8{
	@media (min-width: 1000px){
		width: 66.66667%;
	}
}

.col-lg-9{
	@media (min-width: 1000px){
		width: 75%;
	}
}

.col-lg-10{
	@media (min-width: 1000px){
		width: 83.33333%;
	}
}

.col-lg-11{
	@media (min-width: 1000px){
		width: 91.66667%;
	}
}

.col-lg-12{
	@media (min-width: 1000px){
		width: 100%;
	}
}

////////////////////////////////////////////
/*circle block*/
@mixin circle($size) {
	 width: $size;
	 height: $size;
	 border-radius: 100%;
}
%slick-arrow{
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	margin-top: -30px;
	display: block;
	&:before{
		display: none;
	}
}
%arrow-prev{
	@extend %slick-arrow;
	background: url(#{$imgPath}slider-back-icon.png) no-repeat left top;
	left: 40px;
	&:hover, &:active, &:focus{
		background: url(#{$imgPath}slider-back-icon.png) no-repeat left top;
		opacity: 0.6;
	}
}
%arrow-next{
	@extend %slick-arrow;
	background: url(#{$imgPath}slider-next-icon.png) no-repeat left top;
	&:hover, &:active, &:focus{
		background: url(#{$imgPath}slider-next-icon.png) no-repeat left top;
		opacity: 0.6;
	}
	right: 40px;
}
/*arrow link*/
%arrow-link{
	@extend .icon-icon-arrow-right;
	list-style: none;
	&:before{
		@extend %iconbase;
		position: relative;
		display: inline-block;
		font-size: 1em;
		color: $accent-color;
		width: 20px;
	}
}
.border-radius-base{
	border-radius: 4px;
	img{
		border-radius: 4px;
	}
}
.text-link{
	@extend %arrow-link;
}

%centerise{
	width: 100%;
	max-width:1200px;
	min-width: 980px;
	margin:0 auto;
}

%max-img{
	max-width: 100%;
}
/*hover effect*/
%linkhover{
	-webkit-transition: all 0.8s;
	-moz-transition: all 0.8s;
	-ms-transition: all 0.8s;
	-o-transition: all 0.8s;
	transition: all 0.8s;
	opacity: 1;
	filter: alpha(opacity=100);
	&:hover {
		opacity: 0.4;
		filter: alpha(opacity=60);
	}
}
/*animation*/
%trans-ani{
	-webkit-transition: all 0.8s;
	-moz-transition: all 0.8s;
	-ms-transition: all 0.8s;
	-o-transition: all 0.8s;
	transition: all 0.8s;
}
%trans-ani-bg{
	-webkit-transition: background 0.8s;
	-moz-transition: background 0.8s;
	-ms-transition: background 0.8s;
	-o-transition: background 0.8s;
	transition: background 0.8s;
}
%trans-ani-cl{
	-webkit-transition: color 0.8s;
	-moz-transition: color 0.8s;
	-ms-transition: color 0.8s;
	-o-transition: color 0.8s;
	transition: color 0.8s;
}
%trans-ani-bd{
	-webkit-transition: border 0.8s;
	-moz-transition: border 0.8s;
	-ms-transition: border 0.8s;
	-o-transition: border 0.8s;
	transition: border 0.8s;
}

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
	/*button*/
	.flaticon-btn{
		@extend %normal-link;
		@extend %trans-ani-bg;
		@extend .icon-icon-arrow-right;
		display: block;
		border-radius: 4px;
		background: #fff;
		position: relative;
		border: 1px solid mix(#fff,$accent-color,70);
		padding: 30px 15px 15px 15px;
		&:before{
			@extend %iconbase;
			@extend %trans-ani-cl;
			position: absolute;
			display: block;
			width: 1em;
			height: 1em;
			text-align: center;
			font-size: 10px;
			right: 10px;
			bottom: 10px;
			color: $accent-color;
		}
		p{
			@extend %trans-ani-cl;
			font-size: 14px;
			font-weight: bold;
			color: $accent-color;
			line-height: 140%;
			text-align: center;
		}
		span.text-middle{
			display: block;
		}
		span.btn-image{
			@extend %trans-ani-cl;
			color: $accent-color;
		}
		&.flaticon-btn-orange{
			border: 1px solid mix(#fff,$point_color,70);
			&:before,p,span.btn-image{
				color: $point_color;
			}
			&:hover{
				background: $point_color;
			}
		}
		&:hover,&:active,&:focus{
			background: $accent_color;
			&:before,p,span.btn-image{
				color: #fff;
			}
		}
	}
	.flat-btn{
		@extend .btn;
		@extend %trans-ani;
		border:1px solid mix($accent_color,#fff,50%);
		color: $accent_color;
		line-height: 1.6em;
		padding: 12px 15px;
		font-weight: bold;
		background: #fff;
		i{
			&:before{
				@extend %iconbase;
				position: relative;
				display: inline-block;
				width: 100%;
				text-align: center;
				font-size: 0.8em;
				bottom: 0;
				padding-right: 10px;
			}
			display: inline-block;

			&.glyphicon-menu-down:before{
				content: $icon-icon-download;
			}

		}
		&.blank:after{
			content: url('#{$imgPath}window-icon.png');
			margin-left: 5px;
		}
		&:hover,&:active{
			background: $accent_color;
			color: #fff;
			i{
				color: #fff;
			}
		}
	}
	.btn-green-check{
		@extend .icon-icon-arrow-check;
		border: 1px solid $accent-color;
		background: $accent_color;
		color: #fff;
		&:before{
			@extend %iconbase;
			padding-right: 10px;
		}
		&:hover,&:active{
			background: none;
			color: $accent_color;
		}
	}
	.btn-green-download{
		border: 1px solid $accent-color;
		background: $accent_color;
		color: #fff;
		@extend .icon-icon-download;
		&:before{
			@extend %iconbase;
			padding-right: 10px;
		}
		&:hover,&:active{
			background: none;
			color: $accent_color;
		}
	}
	.btn-orange-arrow{
		@extend .icon-icon-arrow-right;
		border: 1px solid $point-color;
		background: $point_color;
		color: #fff;
		position: relative;
		padding-right: 30px;
		&:before{
			@extend %iconbase;
			display: inline-block;
			position: absolute;
			right: 10px;
			font-size: 10px;
			top: 50%;
			margin-top:-5px;
		}
		&:hover,&:active{
			background: none;
			color: $point_color;
		}
	}
	.btn-fit{
		width: 100%;
	}
	.btn-msize{
		width: 50%;
	}
	.btn-lsize{
		width: 100%;
	}
	.btn-benefit{
		width:80%;
	}
	.more-btn{
		@extend %normal-link;
		@extend %trans-ani-cl;
		@extend .icon-icon-arrow-right;
		color: #222;
		position: absolute;
		width: 7em;
		right: 0;
		top: 15px;
		font-size: 12px;
		font-weight: normal;
		&:before{
			@extend %iconbase;
			font-size: 10px;
			padding: 0 5px;
		}
		&:hover{
			color: mix(#fff,$accent-color,40);
		}
	}
	.information-col {
		.list-btn {
			margin: 20px auto 0px;
			border-radius: 15px;
			font-size: 16px;
			width: 25em !important;
		}
	}

	.list-btn{
		@extend .icon-icon-arrow-right;
		@extend %trans-ani;
		background: #2caa7f;
		margin: 20px auto;
		width: 20em;
		right: 0;
		top: 15px;
		font-size: 14px;
		border-radius: 4px;
		text-align: center;
		padding: 12px 5px;
		font-weight: bold;
		border: 1px solid #2caa7f;
		a{
			color: #fff;
			text-decoration: none;
		}
		&:before{
			font-size: 10px;
			padding: 0 5px;
			color: #fff;
			@extend %iconbase;
		}
		&:hover,
		&:active,
		&:focus{
			background: #fff;
			cursor: pointer;
			border: 1px solid #2caa7f;
			padding: 12px 5px;
			transition: .8s;
			a{
				color: #2caa7f;
			}
		}
	}
	.block-link{
		@extend .row;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.block-link-item{
		a{
			@extend .clearfix;
			@extend %normal-link;
			@extend %trans-ani;
			@extend .icon-icon-arrow-right;
			position: relative;
			margin: -10px;
			padding: 10px;
			display: block;
			color: $font_color;
			&:hover{
				color: $font_color;
				background: mix(#fff,$accent-color,85);
			}
			&:before{
				@extend %iconbase;
				@extend %trans-ani-cl;
				position: absolute;
				display: block;
				width: 1em;
				height: 1em;
				text-align: center;
				font-size: 10px;
				right: 10px;
				bottom: 10px;
				color: $accent-color;
			}
			.image-wrap{
				img{
					width: 100%;
				}
			}
		}
	}


/*grid*/

@for $i from 0 through 11 {
	.col-#{$i + 1} {
		@extend .col-lg-#{$i + 1};
		@extend .col-md-#{$i + 1};
		@extend .col-sm-#{$i + 1};
		@extend .col-xs-#{$i + 1};
		img{
			max-width: 100%;
			height: auto;
		}
	}
}

%col-img{
	img{
		max-width: 100%;
	}
}
.row .freewidth{

}
/*margin*/
@for $i from 0 through 5 {
	.margin-top#{$i * 10} {
		margin-top: #{$i * 10}px!important;
	}
	.margin-topm#{$i * 10} {
		margin-top: -#{$i * 10}px!important;
	}
	.margin-bottom#{$i * 10} {
		margin-bottom: #{$i * 10}px!important;
	}
	.margin-bottomm#{$i * 10} {
		margin-bottom: -#{$i * 10}px!important;
	}
	.margin-right#{$i * 10} {
		margin-right: #{$i * 10}px!important;
	}
	.margin-rightm#{$i * 10} {
		margin-right: -#{$i * 10}px!important;
	}
	.margin-left#{$i * 10} {
		margin-left: #{$i * 10}px!important;
	}
	.margin-leftm#{$i * 10} {
		margin-left: -#{$i * 10}px!important;
	}
	.margin-all#{$i * 10} {
		margin: #{$i * 10}px!important;
	}
}
@for $i from 0 through 5 {
	.padding-top#{$i * 10} {
		padding-top: #{$i * 10}px!important;
	}
	.padding-topm#{$i * 10} {
		padding-top: -#{$i * 10}px!important;
	}
	.padding-bottom#{$i * 10} {
		padding-bottom: #{$i * 10}px!important;
	}
	.padding-bottomm#{$i * 10} {
		padding-bottom: -#{$i * 10}px!important;
	}
	.padding-right#{$i * 10} {
		padding-right: #{$i * 10}px!important;
	}
	.padding-rightm#{$i * 10} {
		padding-right: -#{$i * 10}px!important;
	}
	.padding-left#{$i * 10} {
		padding-left: #{$i * 10}px!important;
	}
	.padding-leftm#{$i * 10} {
		margin-left: -#{$i * 10}px!important;
	}
	.padding-all#{$i * 10} {
		padding: #{$i * 10}px!important;
	}
}
/*accordion*/
.ac-section{
	border-top: 1px solid #e5e5e5;
	h2{
		margin: 20px auto;
		padding: 0 0 0 70px;
	}
}
.ac-btn{
	margin: 0 auto;
	width: 100%;
	max-width: 1200px;
	background-size: 30px 30px;
	border-bottom: 0px;
	font-size: 1.8em;
	&:before{
		display: none;
	}
}
.active .ac-btn{
	background-size: 30px 30px;
}
.ac-area{
	display: none;
	margin: 0 auto 50px;
	width: 100%;
	max-width: 1200px;
	padding: 0 5%;
}
.active .ac-area{
	display: block;
}
.tab-list{
	@extend .clearfix;
	margin: 36px 0 14px;
	padding: 0;
	li{
		float: left;
		border-bottom: 1px solid mix(#fff,$accent-color,60);
		list-style: none;
		text-align: center;
		color: $accent-color;
		font-size: 14px;
		padding: 10px 5px;
		font-weight: bold;
		&.active{
			border: 1px solid mix(#fff,$accent-color,60);
			border-bottom: 0px;
		}
	}
}
.tab-item{
	@extend .clearfix;
	margin-bottom: 10px;
}