$icon-icon-quotation: "\e600";
$icon-icon-document: "\e601";
$icon-icon-seminar: "\e602";
$icon-icon-handshake: "\e603";
$icon-icon-building: "\e604";
$icon-icon-reform: "\e605";
$icon-icon-flat35: "\e606";
$icon-icon-question: "\e607";
$icon-icon-simulation: "\e608";
$icon-icon-arrow-3man: "\e609";
$icon-icon-arrow-balloon: "\e60a";
$icon-icon-close: "\e60b";
$icon-icon-arrow-check: "\e60c";
$icon-icon-download: "\e60d";
$icon-icon-arrow-house: "\e60e";
$icon-icon-arrow-man: "\e60f";
$icon-icon-arrow-right: "\e610";/* slick */
$icon-icon-arrow-right-bold: "\e611";
$icon-icon-arrow-top: "\e612";
$icon-icon-arrow-left: "\e613";/* slick */
$icon-opwindow: "\e614";
$icon-icon-arrow-coin: "\e615";
$icon-icon-arrow-bottom: "\e616";
@font-face {
	font-family: 'zaikeiicon';
	src:url('../fonts/zaikeiicon.eot?-tyyfzk');
	src:url('../fonts/zaikeiicon.eot?#iefix-tyyfzk') format('embedded-opentype'),
		url('../fonts/zaikeiicon.woff?-tyyfzk') format('woff'),
		url('../fonts/zaikeiicon.ttf?-tyyfzk') format('truetype'),
		url('../fonts/zaikeiicon.svg?-tyyfzk#zaikeiicon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'zaikeiicon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-icon-arrow-covid19:before {
	content: "\e601";
}

.icon-icon-video:before {
	content: url("../images/contents/top/icon-video.svg");
	display: block;
	margin-top: -25px;
}

.icon-icon-arrow-coin:before {
	content: "\e615";
}
.icon-icon-arrow-left:before {
	content: "\e613";
}
.icon-icon-arrow-3man:before {
	content: "\e609";
}
.icon-icon-arrow-balloon:before {
	content: "\e60a";
}
.icon-icon-arrow-check:before {
	content: "\e60c";
}
.icon-icon-arrow-house:before {
	content: "\e60e";
}
.icon-icon-arrow-man:before {
	content: "\e60f";
}
.icon-icon-arrow-right:before {
	content: "\e610";
}
.icon-icon-arrow-right-bold:before {
	content: "\e611";
}
.icon-icon-arrow-top:before {
	content: "\e612";
}
.icon-icon-close:before {
	content: "\e60b";
}
.icon-icon-download:before {
	content: "\e60d";
}
.icon-opwindow:before {
	content: "\e614";
}

.icon-icon-quotation:before {
	content: "\e600";
}
.icon-icon-document:before {
	content: "\e601";
}
.icon-icon-seminar:before {
	content: "\e602";
}
.icon-icon-handshake:before {
	content: "\e603";
}
.icon-icon-building:before {
	content: "\e604";
}
.icon-icon-reform:before {
	content: "\e605";
}
.icon-icon-flat35:before {
	content: "\e606";
}
.icon-icon-question:before {
	content: "\e607";
}
.icon-icon-simulation:before {
	content: "\e608";
}
.icon-icon-arrow-bottom:before {
	content: "\e616";
}
%iconbase{
	font-family: 'zaikeiicon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}