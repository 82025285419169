@charset "utf-8";
/*indexpage*//////////////////////////////////////////////
.theme-index{
	.l-property-area{
		box-shadow: 0px 2px 3px -3px rgba(0,0,0,0.4);
		-webkit-box-shadow: 0px 2px 3px -3px rgba(0,0,0,0.4);
		-moz-box-shadow: 0px 2px 3px -3px rgba(0,0,0,0.4);
	}
	.l-global-area{
		box-shadow: 0px -2px 3px -3px rgba(0,0,0,0.4);
		-webkit-box-shadow: 0px -2px 3px -3px rgba(0,0,0,0.4);
		-moz-box-shadow: 0px -2px 3px -3px rgba(0,0,0,0.4);
		height: 132px;
	}
	.l-global-navi,.logo-area{
		height: 132px;
	}
	.logo-area a{
		background: url(#{$imgPath}layout/logo.png) no-repeat 10px 46px;
		height: 132px;
	}
	.l-global-navi{
		li a{
			padding: 55px 0;
		}
		li:after{
			bottom: -9px;
		}
		li:before{
			top: 40px;
		}
	}
	.l-fadenav{
		.l-global-area{
			height: 98px;
		}
		.l-global-navi,.logo-area{
			height: 98px;
		}
		.logo-area a{
			height: 78px;
			background: url(#{$imgPath}layout/logo.png) no-repeat 10px 30px;
		}
		.l-global-navi{
			li a{
				padding: 43px 0 42px;
			}
			li:after{
				bottom: 0px;
			}
			li:before{
				top: 29px;
			}
		}
	}
	.l-cta{
		height: 262px;
	}
}

.theme-index .l-announcement,
.theme-single .l-announcement {
  padding: 5px 20px 15px 20px;
  text-align: right;
  border-top: 1px solid #eeeeee;
}	

.pickup-item-col4{
	a{
		@extend .clearfix;
	}
	@extend .col-lg-4;
	@extend .col-md-4;
	@extend .col-sm-4;
	@extend .col-xs-4;
	@extend %trans-ani;
}
.pickup-item-col2{
	a{
		@extend .clearfix;
	}
	@extend .col-lg-6;
	@extend .col-md-6;
	@extend .col-sm-6;
	@extend .col-xs-6;
	@extend %trans-ani;
	.pickup-index-link{
		a{
			&:before{
				@extend .icon-icon-arrow-right;
				@extend %iconbase;
			}
		}
	}
}
.information-col{
	@extend .col-lg-12;
	@extend .col-md-12;
	@extend .col-sm-12;
	@extend .col-xs-12;
}
.user-action-area{
	@extend .clearfix;
	@extend %width-min;
}
.user-action-item{
	@extend %trans-ani;
	@extend .col-lg-4;
	@extend .col-md-4;
	@extend .col-sm-4;
	@extend .col-xs-4;
	background-position: center center;
	background-repeat: none;
	background-size: 100% 100%;
	text-align: center;
	padding: 0;
	&.user-action-seminar{
		background-image: url(#{$imgPath}contents/top/user-action-seminar-bg.jpg);
	}
	&.user-action-document{
		background-image: url(#{$imgPath}contents/top/user-action-document-bg.jpg);
	}
	&.user-action-simulation{
		background-image: url(#{$imgPath}contents/top/user-action-simulation-bg.jpg);
	}
	&:hover{
		background-size:110% 110%;
		.flat-btn{
			background: none;
			color: $accent-color;
			border: 1px solid $accent-color;
		}
	}
	a{
		@extend %normal-link;
		display: block;
	}
	p{
		color: $accent-color;
		font-size: 20px;
		padding: 45px 10px 20px;
		margin: 0;
		font-weight: bold;
	}
	.flat-btn{
		margin: 0 auto 55px;
		min-width: 200px;
	}
}

.pickup-area{
	@extend .clearfix;
	@extend %width-min;
	font-weight: bold;
	background: #fff;
	.l-main &{
		min-width: 673px;
		margin: 0 -20px;
	}
	.pickup-index {
		display: flex;
		flex-direction: initial;
	  }
	  
}
.pickup-item{
	@extend %trans-ani;
	@extend .col-lg-12;
	@extend .col-md-12;
	@extend .col-sm-12;
	@extend .col-xs-12;
	border: 1px solid #fff;
	border-left: 1px solid #eeeeee;
	padding: 0;
	&:first-child{
		.l-main &{
			border-left: 1px solid #fff;
			&:hover{
				border-left: 1px solid mix(#fff,$accent-color,50);
			}
		}
	}
	a{
		@extend .clearfix;
	}
	&:hover{
		background: mix(#fff,$accent-color,95);
		border: 1px solid mix(#fff,$accent-color,50);
	}
}
.pickup-item{
	&.pickup-resol{
		@extend .col-lg-12;
		@extend .col-md-12;
		@extend .col-sm-12;
		@extend .col-xs-12;
		padding:0;
		a{
			padding: 20px 10px 0 10px;
		}
	}
	a{
		display: block;
		color: $font-color;
		border:5px solid #fff;
		padding: 20px 10px 70px;
	}
}


.pickup-item-col4{
	&.pickup-resol{
		@extend .col-lg-12;
		@extend .col-md-12;
		@extend .col-sm-12;
		@extend .col-xs-12;
		padding:0;
		a{
			padding: 20px 10px;
		}
	}
	border: 1px solid #fff;
	border-left: 1px solid #eeeeee;
	padding: 0;
	&:first-child{
		.l-main &{
			border-left: 1px solid #fff;
			&:hover{
				border-left: 1px solid mix(#fff,$accent-color,50);
			}
		}
	}
	a{
		display: block;
		color: #222222;
		border: 5px solid #fff;
		padding: 20px 10px 70px;
		text-decoration: none;
		.pickup-title-box {
			overflow: hidden;
			text-align: center;
			.theme-index &, 
			.theme-single &{
				height: 66px;
			}
			.theme-multi &{
				height: 76px;
			}
		}
		.pickup-subtitle {
			margin: 0;
			color: #2caa7f;
			line-height: 1.4;
		}
		.pickup-title {
			color: #2caa7f;
			font-size: 22px;
			line-height: 1.4;
			font-weight: bold;
			background: none;
			padding: 0;
			margin: 0 0 0.5em 0;
			.theme-single &, 
			.theme-multi &{
				padding: 0 10px;
			}
		}
	}
	&:hover {
		background: #f4fbf9;
		border: 1px solid #96d5bf;
	}
	.pickup-text,.pickup-interest{
		width: 50%;
	}
	.pickup-interest {
		.theme-single &{
			padding-top: 0;
		}
		.theme-multi &{
			padding-top: 0;
		}
	}

	.pickup-interest-wrap{
		.theme-index &, 
		.theme-single &{
			height: 220px;
		}
		@media only screen and (max-width: 740px){
			.theme-index &, 
			.theme-single &{
				height: 240px;
			}
	}
		.theme-multi &{
			height:  250px;
		}
	}

	.pickup-interest-box{
		text-align: center;
		.pickup-discription,.pickup-interest-num {
		  margin: 0;
		}
	}
	.pickup-discription {
		line-height: 1.4;
		height: 40px;
		margin: 0 !important;
	}
	  .pickup-interest-item {
		width: 50%;
		padding: 0 10px;
		float: left;
		text-align: center;
	}
	.pickup-catch {
		clear: both;
		color: #2caa7f;
		background: #d5eee5;
		border-radius: 4px;
	}
	.pickup-yearly {
		font-size: 24px;
		margin: 0 .2em 0 .5em;
		.theme-multi &{
			font-size: 20px;
		}
	}
	.pickup-interest-pph {
		font-size: 36px !important;
		.theme-multi &{
			font-size: 26px !important;
		}
	}
	.pickup-conditions {
		clear: both;
		min-height: 35px;
		font-size: 12px;
		line-height: 1.4;
		padding-left: 5.5em;
		text-indent: -5em;
		font-weight: normal;
	  }
	  .pickup-conditions.pickup-index {
		min-height: 0 !important;
		margin-bottom: 8px;
	  }
	  .pickup-conditions.pickup-small {
		font-size: 10px;
	  }
	  .pickup-index-link {
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;
		a {
			font-size: 12px;
			font-weight: 400;
			display: inline-block;
			padding: 0 5px;
			&:hover {
				text-decoration: underline;
			  }
			  &:before {
				display: inline-block;
				font-size: 10px;
				color: #ccc;
				padding-right: 10px;
			  }
			  
		  }
	}
	  >.pickup-btn li a {
		padding: 12px 5px;
		&:before {
			content: "";
		  }
	  }
	  .pickup-text,.pickup-interest {
		width: 50%;
		.theme-index &{
			height: 185px;
		}
		.theme-single &{
			height: 165px;
		}
		.theme-multi &{
			height: 205px;
		}
	}


}

.pickup-item-col2 {
	&.pickup-resol{
		@extend .col-lg-12;
		@extend .col-md-12;
		@extend .col-sm-12;
		@extend .col-xs-12;
	}
	border: 1px solid #fff;
	border-left: 1px solid #eeeeee;
	padding: 0;
	&:first-child{
		.l-main &{
			border-left: 1px solid #fff;
			&:hover{
				border-left: 1px solid mix(#fff,$accent-color,50);
			}
		}
	}
	a {
		display: block;
		color: #222222;
		border: 5px solid #fff;
		padding: 20px 10px 80px;
		text-decoration: none;
		.pickup-title-box {
			overflow: hidden;
			text-align: center;
			.theme-index &, 
			.theme-single &{
				height: 66px;
			}
			.theme-multi &{
				height: 76px;
			}
		}
		.pickup-subtitle {
			margin: 0;
			color: #2caa7f;
			line-height: 1.4;
		}
		.pickup-title {
			color: #2caa7f;
			font-size: 22px;
			line-height: 1.4;
			font-weight: bold;
			background: none;
			padding: 0;
			margin: 0 0 0.5em 0;
			.theme-single &, 
			.theme-multi &{
				padding: 0 10px;
			}
		}
	}
	&:hover {
		background: #f4fbf9;
		border: 1px solid #96d5bf;
	}
	.pickup-text,.pickup-interest {
		width: 50%;
		.theme-index &{
			height: 185px;
		}
		.theme-single &{
			height: 165px;
		}
		.theme-multi &{
			height: 205px;
		}
	}
	.pickup-interest-wrap{
		.theme-index &, 
		.theme-single &{
			height: 220px;
		}
		@media only screen and (max-width: 740px){
			.theme-index &, 
			.theme-single &{
				height: 240px;
			}
	}
		.theme-multi &{
			height:  250px;
		}
	}

	.pickup-interest-box{
		text-align: center;
		.pickup-discription,.pickup-interest-num {
		  margin: 0;
		}
	}
	.pickup-discription {
		line-height: 1.4;
		height: 40px;
		margin: 0 !important;
	}
	  .pickup-interest-item {
		width: 50%;
		padding: 0 10px;
		float: left;
		text-align: center;
	}
	.pickup-catch {
		clear: both;
		color: #2caa7f;
		background: #d5eee5;
		border-radius: 4px;
	}
	.pickup-yearly {
		font-size: 24px;
		margin: 0 .2em 0 .5em;
		.theme-multi &{
			font-size: 20px;
		}
	}
	.pickup-interest-pph {
		font-size: 36px !important;
		.theme-multi &{
			font-size: 26px !important;
		}
	}
	.pickup-conditions {
		clear: both;
		min-height: 35px;
		font-size: 12px;
		line-height: 1.4;
		padding-left: 5.5em;
		text-indent: -5em;
		font-weight: normal;
	  }
	  .pickup-conditions.pickup-index {
		min-height: 0 !important;
		margin-bottom: 8px;
	  }
	  .pickup-conditions.pickup-small {
		font-size: 10px;
	  }

	  .pickup-index-link {
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;
		a {
			font-size: 12px;
			font-weight: 400;
			display: inline-block;
			padding: 0 5px;
			&:hover {
				text-decoration: underline;
			  }
			  &:before {
				display: inline-block;
				font-size: 10px;
				color: #ccc;
				padding-right: 10px;
			  }
		  }
	  }


	  >.pickup-btn li a {
		padding: 12px 5px;
		&:before {
			content: "";
		  }
	  }

}


.pickup-text{
	@extend .col-lg-7;
	@extend .col-md-7;
	@extend .col-sm-7;
	@extend .col-xs-7;
	.pickup-title{
		color: $accent-color;
		text-align: left;
		margin-bottom: 10px;
		font-weight: bold;
		font-size: 22px;
		background: none;
		padding: 0;
		.l-main &{
			font-size: 20px;
		}
	}
	.pickup-discription{
		line-height: 1.6em;
		font-size: 13px;
		.l-main &{
			font-weight: normal;
			font-size: 12px;
		}
	}
}
.pickup-resol {
	.pickup-btn{
		li{
			a{
				@extend %trans-ani;
				@extend .flat-btn;
				i{
					&:before{
						@extend .icon-icon-arrow-right;
						@extend %iconbase;
					}
				}
			}
		}

	}
	.pickup-text{
		width: 30%;
		.pickup-subtitle {
			margin: 0;
			color: #2caa7f;
			line-height: 1.4;
		}
		.pickup-title{
			margin-top: 10px;
		}
		.pickup-conditions {
			font-size: 12px;
			line-height: 1.4;
			padding-left: 5em;
			text-indent: -5em;
			font-weight: normal;
		  }
		  
	}
}

.pickup-interest{
	@extend .col-lg-5;
	@extend .col-md-5;
	@extend .col-sm-5;
	@extend .col-xs-5;
	text-align: center;
	.pickup-interest-title {
		font-size: 16px;
		margin-bottom: 0;
		line-height: 1.2em;
		.font-small{
			display: block;
			font-size: 11px;
		}
	}
	.pickup-interest-num{
		font-size: 26px;
		line-height: 1.4;
		line-height: 50px;
		.l-main &{
			font-size: 16px;
		}
	}
	.pickup-interest-pph{
		font-size: 46px;
		.l-main &{
			font-size: 40px;
		}
	}
}
.pickup-interest-num {
	margin: 0;
	line-height: 1.6;
	.theme-index &, 
	.theme-single &{
		height: 90px;
	}
	.theme-multi &{
		height: 90px;
	}
}
.pickup-resol{
.pickup-interest{
	width: 50%;
	.pickup-catch {
		clear: both;
		color: #2caa7f;
		background: #d5eee5;
		border-radius: 4px;
	  }
	  .pickup-interest-wrap {
		overflow: hidden;
		.pickup-interest-item {
			width: 50%;
			padding: 0 10px;
			float: left;
		}
	  }
}
}

.pickup-btn{
	position: absolute;
	top: 335px;
	right: 0;
	padding: 0 10px;
	width: 100%;
	ul{
		padding: 0;
		margin: 0;
	}
	li{
		@extend .col-lg-6;
		@extend .col-md-6;
		@extend .col-sm-6;
		@extend .col-xs-6;
		list-style: none;
		margin: 0;
		a{
			@extend .flat-btn;
			@extend .icon-icon-arrow-right;
			display: block;
			position: relative;
			.l-main &{
				font-size: 13px;
			}
			&:before{
				position: absolute;
				right: 10px;
				top: 20px;
				width: 1em;
				height: 1em;
				@extend %iconbase;
				font-size: 10px;
				display: inline;
			}
		}
	}
	.pickup-btn-down {
		top: 350px;
	  }
}

.pickup-resol{
	.pickup-btn {
		width: 20%;
		right: 0;
		top: 50px;
		bottom: 0;
		li {
			width: 100%;
			margin-bottom: 10px;
			a {
				width: 100%;
			  }
		  }
	  }
	  
}


.information-area{
	@extend %width-fit;
	@extend .clearfix;
	margin: 50px auto;

	h3{
		border-bottom: 2px solid #eeeeee;
		font-size: 13px;
		padding: 15px 5px;
		position: relative;
		text-align: left;
		font-weight: bold;
		color: #222;
		background: none;
		&:after{
			position: absolute;
			content: ' ';
			width: 100px;
			height: 2px;
			background: $accent-color;
			bottom: -2px;
			left: 0;
		}
	}
	.information-col{
		&:first-child{
			h3{
				padding: 0px 5px 15px 5px;
			}
		}
	  }
	  p.select_event {
		color: #2caa7f;
		margin-bottom: 0;
		text-align: center;
		font-weight: bold;
	  }

}


.external-ban-area{
	@extend .clearfix;
	@extend %width-fit;
	margin: 40px auto;
	li{
		list-style: none;
		@extend .col-lg-3;
		@extend .col-md-3;
		@extend .col-sm-3;
		@extend .col-xs-3;
		a img{
			@extend %trans-ani;
		}
		a:hover img{
			opacity: .5;
		}
	}
}
.external-ban-item{
	a{
		display: block;
		position: relative;
		border-radius: 4px;
		overflow: hidden;
	}
	a[target="_blank"]:after{
		@extend %iconbase;
		position: absolute;
		display: block;
		content: $icon-opwindow;
		font-size: 16px;
		color: #666;
		right: 10px;
		bottom: 10px;
	}
}
.attention-area{
	@extend %width-fit;
	margin: 40px auto;
	p{
		background: mix(#fff,$point-color,90);
		margin: 0 10px;
		font-weight: bold;
		padding: 20px;
		border-radius: 4px;
		text-align: center;
	}
}
/*entry*//////////////////////////////////////////////////
.product-list{
	margin-top: 20px;
	background: #f9f9f9;
}
.product-list-contents{
	@extend .clearfix;
	padding: 10px 0 0 0;
	&:first-child{
		padding: 50px 0 0 0;
	}
	&:last-child{
		padding: 10px 0 50px 0;
	}
	h2{
		text-align: center;
		border-bottom: none;
		padding: 0 0 20px 0;
		&:before{
			display: none;
		}
		span{
			display: inline-block;
			border-bottom: 2px solid $accent-color;
			padding-bottom: 10px;
		}
	}
	h3{
		color: #2caa7f;
		background: none;
		margin: 0;
	}
}
.l-contents.theme-single .product-list-contents{
	@extend %centerise;
}
.product-list-item{
	@extend .col-lg-4;
	@extend .col-md-4;
	@extend .col-sm-4;
	@extend .col-xs-4;
	a{
		@extend %normal-link;
		@extend %trans-ani;
		@extend .icon-icon-arrow-right;
		display: block;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		padding: 20px;
		margin-bottom: 20px;
		color: $font_color;
		position: relative;
		overflow: hidden;
		&:before{
			@extend %iconbase;
			display: inline-block;
			position: absolute;
			right: 10px;
			font-size: 10px;
			bottom: 10px;
			color: $accent-color;
		}
		&:after{
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 2px;
			bottom: 0;
			left: 0;
			background: #c8eae0;
		}
		&:hover{
			background: $accent-color;
			border-color: $accent-color;
			color: #fff;
			h4,p,&:before{
				color: #fff;
			}
			&:after{
				background: $accent-color;
			}
		}
	}
	p{
		display: none;
		a{
			display: none;
		}
	}
	h4.product-list-title{
		display: block;
		@extend %trans-ani;
		border-left: 0;
		margin-top: 0;
		padding: 0;
		color: $accent-color;
	}
	p.product-list-text{
		display: block;
		@extend %trans-ani;
		color: $font_color;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
/*qaarea*/////////////////////////////////////////////////
.qa-area{
	.ac-section{
		border-top: 0;
		position: relative;
		padding: 0 25px 0 0;
		&:before{
			position: absolute;
			display: inline-block;
			content: '+';
			color: #fff;
			border:1px solid #eee;
			background: #ccc;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			text-align: center;
			line-height: 16px;
			font-weight: normal;
			right: 0;
			top: 12px;
		}
		&.active:before{
			content: '-';
		}
	}
	h4.ac-btn{
		@extend %trans-ani;
		font-size: 16px;
		border-left: 0;
		background: none;
		position: relative;
		padding: 8px 10px 8px 28px;
		border-radius: 4px;
		&:before{
			position: absolute;
			content: 'Q';
			display: block;
			top: 8px;
			left: 8px;
			color: $accent-color;
		}

		&:hover{
			background: mix(#fff,$accent-color,85);
		}
	}
	.ac-area{
		position: relative;
		padding: 0px 10px 0px 28px;
		margin: 0 auto 20px;
		&:before{
			position: absolute;
			content: 'A';
			display: block;
			top: 0px;
			left: 8px;
			color: $point-color;
			font-weight: bold;
			font-size: 16px;
		}
	}
}
/*404error*/////////////////////////////////////////////////
.error-area{
	text-align: center;
	padding: 100px 0;
	.page-title{
		border-left: 0;
		font-size: 80px;
		color: $accent-color;
	}
	p{
		font-size: 16px;
	}
	.arrow-link{
		display: inline-block;
		margin: 0 10px;
	}
}
/*case*/////////////////////////////////////////////////////
.case-tab{
	display: none;
	position: fixed;
	width: 80px;
	height: 240px;
	bottom: 10px;
	left: 0;
	background: #e9f6f2;
	border-radius: 4px;
	z-index: 9;
	.tab-list{
		position: absolute;
		@extend .clearfix;
		margin: 0;
		padding: 0;
		left: 0;
		top: 0;
		li{
			width: 60px;
			float: none;
			border: 1px solid mix(#fff,$accent-color,60);
			list-style: none;
			text-align: center;
			color: $accent-color;
			font-size: 14px;
			padding: 10px 5px;
			font-weight: bold;
			background: #fff;
			margin: 8px 10px;
			&.active{
				border: 1px solid mix(#fff,$accent-color,60);
				border-bottom: 0px;
				background: $accent-color;
				color: #fff;
			}
		}
	}
	.tab-contents{
		position: relative;
		left: 80px;
		width: 150px;
		height: 240px;
		border-radius: 4px;
	}
	.tab-item{
		display: none;
		.col-3{
			width: 100%;
			margin: 10px 0 0;
		}
	}
}
.case-item{
	border: 1px dotted #ddd;
	margin: 30px 0 0;
	padding: 30px;
}
.case-status{
	@extend .clearfix;
}
.case-number{
	float: left;
	width: 12%;
	padding: 26px 10px;
	color: $accent-color;
	border: 1px solid mix(#fff,$accent-color,10);
	text-align: center;
	line-height: 1.6em;
	.case-number-title{
		display: block;
		font-size: 14px;
	}
	.case-number-number{
		font-size: 24px;
		display: block;
	}
}
.case-title{
	float: left;
	width: 44%;
	padding: 22px 20px 0;
	border: 1px solid mix(#fff,$accent-color,10);
	border-left: 0px;
	h4{
		color: $accent-color;
		font-size: 20px;
		margin: 0;
		border-left: 0;
		padding: 0;
		line-height: 1.4em;
	}
	p{
		font-size: 12px;
		margin-bottom: 0;
		line-height: 1.2em;
	}
}
.case-data{
	float: left;
	width: 44%;
	padding: 0;
	li{
		float: left;
		width: 33.333333%;
		padding: 26px 10px;
		margin: 0;
		border: 1px solid mix(#fff,$accent-color,10);
		border-left: 0px;
		list-style: none;
		text-align: center;
		line-height: 1.6em;
		font-weight: bold;
	}
	.case-data-title{
		display: block;
		font-size: 12px;
	}
	.case-data-data{
		display: block;
		font-size: 14px;
		color: $accent-color;
	}
}
.row.case-product{
	dl{
		dt{
			float: left;
			background: $accent-color;
			text-align: center;
			color: #fff;
			padding: 2px 30px;
			font-size: 12px;
		}
		dd{
			float: left;
			margin-left: 20px;
		}
	}
}

.case-product.information-area{
	dl{
		dt{
			float: left;
			background: $accent-color;
			text-align: center;
			color: #fff;
			padding: 2px 30px;
			font-size: 12px;
		}
		dd{
			float: left;
			margin-left: 20px;
		}
	}
}

/*form*/////////////////////////////////////////////////
.mw_wp_form,#SeminarForm{
	dl{
		@extend .row;
		margin-top: 20px;
		dt{
			clear: both;
			float: left;
			@extend .col-lg-3;
			@extend .col-md-3;
			@extend .col-sm-3;
			@extend .col-xs-3;
			color: $accent-color;
			font-size: 14px;
			&.form-name{
				padding: 8px 10px;
			}
		}
		dd{
			float: left;
			@extend .col-lg-9;
			@extend .col-md-9;
			@extend .col-sm-9;
			@extend .col-xs-9;
			input[type="text"],input[type="email"],input[type="tel"]{
				@extend .form-control;
				width: auto;
				border-radius:2px;
				display: inline-block;
				box-shadow: none;
				margin: 0 5px;
			}
		}
	}
	input[type="button"],input[type="submit"]{
		@extend .flat-btn;
		@extend .btn-msize;
		display: block;
		margin: 0 auto;
	}
	label{
		font-weight: normal;
	}
	select{
		padding: 6px 12px;
		border: 1px solid #ccc;
		font-size: 14px;
		border-radius:2px;
		margin-right: 10px;
	}
	input[type="radio"],input[type="checkbox"]{
		margin: 0 5px;
	}
	textarea{
		width: 100%;
		padding: 6px 12px;
		border: 1px solid #ccc;
		font-size: 14px;
		border-radius:2px;
	}
	.privacy-area{
		overflow-y: scroll;
		height: 300px;
		border:1px solid #eee;
		padding: 20px;
		margin: 20px 0;
	}
	.error{
			color: #f30006 !important;
			font-weight: bold;
	}

	.privacy-check-wrapper{
		display: block;
		margin-bottom: 20px;
		text-align: center;
		._privacy-check{
			position: relative;
			width: 20px;
			height: 20px;
			border: 1px solid #2caa7f;
			border-radius: 5px;
			vertical-align: -5px;
			margin-right:10px;
			-webkit-appearance: none;
			   -moz-appearance: none;
					appearance: none;
			&:checked:before {
				position: absolute;
				top: 1px;
				left: 6px;
				transform: rotate(50deg);
				width: 7px;
				height: 13px;
				border-right: 2px solid #2caa7f;
				border-bottom: 2px solid #2caa7f;
				content: '';
			}
			&:focus {
				outline: none;
				box-shadow: none;
			}
			
		}
		span{
			font-size:18px;
			font-weight: bold;
		}
	}

}
#SeminarForm{
	h1{
		.date{
			display: block;
			color: $accent-color;
			font-size: 16px;
			margin-bottom: 15px;
		}
	}
	.seminar-detail{
		padding: 15px 0;
	}
	.control_box{
		ul{
			padding: 0;
		}
		li{
			list-style: none;
			display: inline-block;
		}

		ul.disc{
			padding: 0 0 0 20px;
			li{
				list-style:disc;
				display:list-item;
			}
		}
	}
	dl.dl_list02{
		dt{
			width: 70px;
			float: left;
		}
		dd{
			float: none;
			width: 100%;
			padding-left: 70px;
		}
	}
	table{
		*:last-child{
			margin-bottom: 0;
		}
	}
	input{
	}
	.seminer-input{
		padding: 20px;
	}
	.seminer-questionnaire{
		@extend .flame-bg;
		@extend .flame-orange;
		background: mix(#fff,$point-color,95);
		margin: 0 0 40px 0px;
		dt{
			padding-left: 20px;
		}
	}
	input[type="submit"]{
		margin: 20px auto;
		display: block;
	}
	.seminer-questionnaire{
		input[type="text"]{
			@extend .form-control;
			width: auto;
			border-radius:2px;
			display: inline-block;
			box-shadow: none;
		}
	}
	#privacy_wrap{
		#inquiry{
			display: block;
			font-size: 16px;
			font-weight: bold;
		}
	}
}
/*simulation*/////////////////////////////////////////////////
.simulation-area{
	input[type="text"]{
		@extend .form-control;
		width: auto;
		border-radius:2px;
		display: inline-block;
		box-shadow: none;
		margin: 0 5px;
	}
	.consumers-area{
		@extend .flame-bg;
		background: mix(#fff,$accent-color,93);
		margin-bottom: 20px;
	}
	.bline{
		tbody tr:nth-child(2n){
			background: mix($accent-color,#fff,0%);
		}
		tbody tr:nth-child(2n+1){
			background: mix($accent-color,#fff,3%);
		}
	}
	.input-radio-list{
		@extend .clearfix;
		padding: 0;
		margin: 0;

		li{
			list-style: none;
			display: inline-block;
			padding: 0;
			margin: 0;
			padding-right: 10px;
			label{
				font-weight: normal;
			}
		}
	}
	th{
		line-height: 1.4em;
	}
}
.error_box{
	font-size: 11px;
	line-height: 1.4em;
	text-align: left;

	color: mix(#000,$point_color,10);
	p{
		display: none;
		border: 1px solid mix(#fff,$point_color,80);
		border-radius: 4px;
		padding: 5px;
		margin: 0;
		margin-top: 5px;
		&:before{
			content: '*';
		}
	}
}
.simulation-result{
	padding: 30px;
	.simulation-arrow{
		position: relative;
		height: 0px;
		text-indent: -200%;
		margin: -15px 0 60px;
		&:after{
			display: block;
			content: '';
			position: absolute;
			bottom: -30px;
			left: 50%;
			margin-left: -70px;
			width: 0;
			height: 0;
			border-top: 30px solid mix(#fff,$accent-color,10);
			border-left: 70px solid transparent;
			border-right: 70px solid transparent;
		}
	}
	h4{
		border-left:2px solid $point-color;
	}
	.bline{
		thead + tbody > tr:first-child{
			border-top: 1px solid mix($point-color,#fff,70%);
		}
		tbody > tr >th + td{
			border-left: 1px dotted mix($point-color,#fff,70%);
		}
		tr{
			border-bottom: 1px solid mix($point-color,#fff,15%);
			&.border-bold{
				border-bottom: 2px solid mix($point-color,#fff,50%);
			}
		}
		thead tr{
			border-bottom: 0px;
		}
		tbody tr:nth-child(2n+1){
			background: mix($point-color,#fff,5%);
		}
		tbody > tr:first-child{
			border-top: 1px solid mix($point-color,#fff,15%);
		}
		td{
			border:none;
			span{
				display: inline-block;
				padding: 10px;
				font-size: 16px;
			}
		}
		th{
			border:none;
			background:none;
			color: mix($point-color,#000,95%);
		}
	}
}
.combination-use{
	background: url(#{$imgPath}contents/simulation_image_02_bg.jpg) no-repeat 35px #f6f6f6 center;
	background-size: cover;
	padding: 30px 0 50px;
	.combination-btn{
		width: 50%;
		margin: 0 auto;
		text-align: center;
	}
}
.simulation-iframe{
	padding: 20px 0;
	iframe{
		width: 100%;
		height: 1000px;
		border: none;
	}
	.ac-area{
		padding: 0;
		margin-top: 20px;
	}
}
/*lp NTT*//////////////////////////////////////////////
.fz24{
	font-size: 24px;
}
.ss{
	background-color: #FFF4E6;
	padding: 20px;
}


/*form-counseling*/

.counseling {
	.title {
		display: none;
	}
	.maker-logo {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		li {
			display: flex;
			align-items: center;
			width: 25%;
	
			img {
				padding: 15px;
				width: 100%;
			}
		}
	}
	
	.maker-list {
		display: flex;
		flex-wrap: wrap;
		h5 {
			width: 100%;
			color: black;
		}
		span {
			width: 50%;
			height: 170px;
			display: flex;
			align-items: center;
			padding: 0;
			flex-wrap: wrap;
			.wpcf7-form-control {
				width: 100%;
				.wpcf7-list-item {
					width: 100%;
					label {
						padding: 10px;
						width: 100%;
						margin: 5px;
						background-color: #f6f6f6;
						border-radius: 3px;
						border: 1px solid #e0e0e0;
						display: flex;
						align-items: center;
						font-weight: bold;
						background-size: 100px;
						input {
							margin: 0;
						}
						.wpcf7-list-item-label {
							width: 100%;
							margin-top: -30px;
							line-height: 1.3em;
							padding-left: 120px;
							padding-top: 45px;
							&:after {
								width: 100%;
								height: 50px;
								margin-top: -38px;
								font-weight: normal;
								line-height: 1.3em;
							}
						}
					}
				}
			}
		}
		#chk1 {
				label {
					background: url(../../common/images/layout/counseling/list/panasonic/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'コンセプトや商品、会社概要などトータルにご紹介';
				}
			}
		}
		#chk2 {
				label {
					background: url(../../common/images/layout/counseling/list/panasonic/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'ご新居実例とインテリア実例を一冊にまとめた最新実例コレクション';
				}
			}
		}
		#chk3 {
				label {
					background: url(../../common/images/layout/counseling/list/panasonic/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '住まいの資金と税金に関する情報をまとめた一冊';
				}
			}
		}
		#chk4 {
				label {
					background: url(../../common/images/layout/counseling/list/panasonic/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'くらしの夢にお答えする「３つの価値」や実例など、当社のリフォームがわかる一冊';
				}
			}
		}
		#chk5 {
				label {
					background: url(../../common/images/layout/counseling/list/mitui/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '三井ホームの総合ご案内';
				}
			}
		}
		#chk6 {
				label {
					background: url(../../common/images/layout/counseling/list/mitui/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '外観・インテリア';
				}
			}
		}
		#chk7 {
				label {
					background: url(../../common/images/layout/counseling/list/mitui/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '技術冊子';
				}
			}
		}
		#chk8 {
				label {
					background: url(../../common/images/layout/counseling/list/mitui/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '地震に備える解説書';
				}
			}
		}
		#chk9 {
				label {
					background: url(../../common/images/layout/counseling/list/yamada/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'ヤマダホームズの住まいのラインナップをご紹介';
				}
			}
		}
		#chk10 {
				label {
					background: url(../../common/images/layout/counseling/list/yamada/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '入居日から快適生活、フル装備注文住宅';
				}
			}
		}
		#chk11 {
				label {
					background: url(../../common/images/layout/counseling/list/yamada/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '室内環境において、より健康により配慮した高性能住宅';
				}
			}
		}
		#chk12 {
				label {
					background: url(../../common/images/layout/counseling/list/yamada/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '推奨プラン「140プラン」';
				}
			}
		}
		#chk13 {
				label {
					background: url(../../common/images/layout/counseling/list/hebel/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '初期保証のみで30年が実現。これなら納得';
				}
			}
		}
		#chk14 {
				label {
					background: url(../../common/images/layout/counseling/list/hebel/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '土地活用・相続税対策にも重要なポイント';
				}
			}
		}
		#chk15 {
				label {
					background: url(../../common/images/layout/counseling/list/hebel/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'へーベルハウスのこだわり建材';
				}
			}
		}
		#chk16 {
				label {
					background: url(../../common/images/layout/counseling/list/hebel/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'これからの住宅は、自己防災対策が欠かせません';
				}
			}
		}
		#chk17 {
				label {
					background: url(../../common/images/layout/counseling/list/misawa/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '成功する住まいづくりの方法を詳しく解説';
				}
			}
		}
		#chk18 {
				label {
					background: url(../../common/images/layout/counseling/list/misawa/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '住まいづくりのマネープラン・ヒントブック';
				}
			}
		}
		#chk19 {
				label {
					background: url(../../common/images/layout/counseling/list/misawa/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '「賢いマイホーム計画」税金・資金計画・工法・間取りの決め方等';
				}
			}
		}
		#chk20 {
				label {
					background: url(../../common/images/layout/counseling/list/misawa/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '一戸建・マンションのリフォーム実例集';
				}
			}
		}
		#chk21 {
				label {
					background: url(../../common/images/layout/counseling/list/sumitomo/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '強さと設計自由度を両立するビッグフレーム構法';
				}
			}
		}
		#chk22 {
				label {
					background: url(../../common/images/layout/counseling/list/sumitomo/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'お客様の建築実例のご紹介';
				}
			}
		}
		#chk23 {
				label {
					background: url(../../common/images/layout/counseling/list/sumitomo/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '建物間口と奥行別に42邸のプランご紹介';
				}
			}
		}
		#chk24 {
				label {
					background: url(../../common/images/layout/counseling/list/sumitomo/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '木質感あふれる実例のご紹介';
				}
			}
		}
		#chk25 {
				label {
					background: url(../../common/images/layout/counseling/list/daiwa/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'ダイワハウスの鉄骨住宅・木造住宅のカタログです';
				}
			}
		}
		#chk26 {
				label {
					background: url(../../common/images/layout/counseling/list/daiwa/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'ダイワハウスの鉄骨住宅（ジーヴォ　シグマ）のカタログです';
				}
			}
		}
		#chk27 {
				label {
					background: url(../../common/images/layout/counseling/list/daiwa/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '分譲住宅の選び方をわかりやすくご案内';
				}
			}
		}
		#chk28 {
				label {
					background: url(../../common/images/layout/counseling/list/daiwa/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'ハウスメーカー選びのポイントをわかりやすくご案内';
				}
			}
		}
		#chk29 {
				label {
					background: url(../../common/images/layout/counseling/list/sekisui/1.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: 'セキスイハイムの鉄骨住宅・木質住宅のカタログです';
				}
			}
		}
		#chk30 {
				label {
					background: url(../../common/images/layout/counseling/list/sekisui/2.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '電力の自給自足に近づくスマートハイムのフラッグシップモデル';
				}
			}
		}
		#chk31 {
				label {
					background: url(../../common/images/layout/counseling/list/sekisui/3.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '「邸宅」と呼ぶにふさわしい、美しく豊かな空間';
				}
			}
		}
		#chk32 {
				label {
					background: url(../../common/images/layout/counseling/list/sekisui/4.png) no-repeat 35px #f6f6f6;
				}
			.wpcf7-list-item-label {
				&:after {
					content: '２×６工法の快適さはそのままに強度と精度がさらに進化した価値ある住まい';
				}
			}
		}
	}
}


// フォーム打ち消し用
span{
	&.wpcf7-list-item-label{
		&:before{
			content:none!important;
		}
	}
}
.mw_wp_form {
	.horizontal-item + .horizontal-item {
		margin: 0!important;
	}
}
.wpcf7c-force-hide + .wpcf7c-elm-step2 {
	.maker-list {
		.CatalogSelect {
			display: none;
		}
	}
} 


.is-select{
	display: block !important;
}

.wpcf7-not-valid-tip {
	text-align: left;
    font-weight: bold;
}

.counseling .error-text {
	display: none;
}

.invalid .counseling .error-text {
	color: #F00;
    font-size: 1em;
	display: block;
	font-weight: bold;
}

a.height-siz-100button {
	width: fit-content;
	height: 100px !important;
	display: flex;
    justify-content: center;
    align-items: center;
}

/*seminar-calendar*/
.theme-multi.calendar {
	.l-contents.theme-multi {
		display: flex;
		flex-wrap: wrap;
		.l-side.seminar {
			width: 20% !important;
			.l-local-navi {
				li {
					ul {
						li {
							a {
								border: 1px solid #96d5bf;
								margin-bottom: 10px;
								font-size: 15px;
							}
							.active {
								background: #19A686;
								color: #FFF;
								&:before {
									color: #FFF;
								}
							}
						}
					}
				}
			}
		}
		.l-main-calenda {
			width: 52% !important;
			border-right: 2px solid #f8f8f8;
		}
		#container {
			width: 28%;
			padding: 50px 20px;
			text-align: center;
			.calendar_button {
				line-height: 1em;
				margin: 0;
				margin-bottom: 30px;
				tbody {
					tr {
						border-left: 2px solid #2caa7f;
						border-right: 2px solid #2caa7f;
						border-bottom: 2px solid #2caa7f;
						&:first-child {
							border-left: none;
							border-right: none;
						}
						th {
							border: none;
							background-color: #2caa7f;
							padding: 0;
							color: #FFFFFF;
							font-size: 0.9em;
							a {
								color: #FFFFFF;
								display: inline-block;
								width: 32%;
								padding: 10px 5px;
								margin: 0;
								border-bottom: none;
								&:hover {
									text-decoration: none;
									background: #e9f6f2;
									color: #2caa7f;
									transition: 0.5s;
								}
							}
						}
						.monthly {
							background: #fff;
							.is-active {
								background: #2caa7f;		
								color: #e9f6f2;			
							}
							a {
								background: #c0e9dc;
								color: #2caa7f;
								border: none;
								border-radius: 5px 5px 0 0;	
								&:hover {
									text-decoration: none;
									background: #2caa7f;		
									color: #e9f6f2;
									border-radius: 5px 5px 0 0;
									transition: 0.5s;
								}
							}
						}
						td {
							padding: 0;
							border: none;
							line-height: 100%;
							table {
								margin: 0;
								tbody {
									display: table-row-group;
									vertical-align: middle;
									border-color: inherit;
									tr {
										display: table-row;
										vertical-align: inherit;
										border-left: none;
										border-right: none;
										border-bottom: none;
										td {
											border: 1px solid #2caa7f;
											text-align: right;
											max-width: 20px;
											height: 30px;
											&:hover {
												a {
													background-color: #fff4e6;
													transition: 0.5s;
												}
											}
											a {
												color: #222;
												cursor: pointer;
												display: inline-flex;
												padding: 5px;
												border: 1px solid #FFC682;
												border-radius: 50px;
												text-align: center;
												width: 40px;
												height: 37px;
												align-items: center;
												justify-content: center;
												&:hover {
													background-color: #fff4e6;
													border-radius: 50px;
													transition: 0.5s;
													text-decoration: none;
												}
											}
											table {
												margin: 0;
											}
										}
										td.active {
											a {
												background-color: #FFC682;
												border-radius: 50px;
											}
										}
									}
									.sat {
										background: #e0eeff;
									}
									.sun {
										background: #ffe0e0;
									}
									.off {
										background-color: #E2E2E2 !important;
										border-radius: 0;
										a {
											border: none;
										}
										&:hover {
											background-color: #E2E2E2;
											border-radius: 0;
											a {
												background-color: #E2E2E2;
												border-radius: 0;
											}
										}
									}
								}
							}
						}
					}
					.weekly {
						th {
							border-right: 1px solid #fff;
							border-top: 1px solid;
							max-width: 20px;
							&:last-child {
								border-right: none;
							}
						}
					}
					.monthly {
						border: none;
						.is-active {
							background: #e9f6f2;
							color: #2caa7f;
						}
					}
				}
			}
			p.text-left{
				strong{
				   font-size:1.1em;
			   }
			}
			input[type="submit"] {
				border: none;
				border-radius: 5px;
				background-color: #2caa7f;
				height: 50px;
				color: #fff;
				padding: 10px 80px;
				margin-top: 10px;
				&:hover {
					background-color: #ff8f09;
					transition: 0.5s;
				}
			}
			.monthly_B,.monthly_C {
				display: none;
			}
			#monthly_B {
				margin: 0 5px;
			}
			.category {
				margin-bottom: 20px;
				text-align: left;
				p {
					margin-bottom: 5px;
				}
				.select {
					position: relative;
					overflow: hidden;
					&:before {
						content: "";
						display: block;
						width: 50px;
						height: 50px;
						background: #2CAA7F;
						position: absolute;
						right: 0;
						top: 0;
						border-radius: 0 5px 5px 0;
						z-index: -9;
					}
					&:after {
						content: "";
						width: 16px;
						height: 16px;
						display: block;
						border: 8px solid transparent;
						border-top: 8px solid white;
						position: absolute;
						right: 16px;
						top: 20px;
						z-index: -1;
					}
					select {
						width: 100%;
						border: 1px solid #2CAA7F;
						padding: 10px;
						border-radius: 5px;
						-webkit-appearance:none;
						-moz-appearance:none;
						text-indent: .01px;
						text-overflow: "";
						appearance:none;
						background: none;
					}
				}
				.select.active {
					&:after {
						content: "";
						width: 16px;
						height: 16px;
						display: block;
						border: 8px solid transparent;
						border-bottom: 8px solid white;
						position: absolute;
						right: 16px;
						top: 13px;
						z-index: -1;
					}
				}
			}
		}
	}
	.seminar-area {
		height: 630px;
		overflow: scroll;
		overflow-x: hidden;
		.seminar-item {
			a {
				width: 100%;
			}
		}
	}
}

a.height-siz-100button {
	width: fit-content;
	height: 100px !important;
	display: flex;
    justify-content: center;
    align-items: center;
}

.seminar-tab {
	height: 460px;
	overflow: scroll;
	overflow-x: hidden;
}

table{
	&.table-wide{
		width:120%;
	}
}




// 画像モーダル
  #gray-display {
	display: none;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100% !important;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
  }
  
  #gray-display img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	max-width: 70%;
	max-height: 70%;
	height: 70%;
	object-fit: contain;
  }

  .close-btn{
	cursor:pointer;
	color:#fff;
	position: absolute;
	right: 30px;
    top: 0px;
	font-size:5rem;
  }

//   スタイルなしリスト
	.no-style{
		list-style: none;
		padding-left: 0;
		margin-left: 0;
	}