@charset "utf-8";
body {
	font-family: $FontFamily;
	color:#222;
	-webkit-text-size-adjust: 100%;
	vertical-align: bottom;
}
html{
	overflow-y: scroll;
	word-wrap: break-word;
}
img{
	max-width:100%;
}
.l-header{
	width: 100%;
}
.l-fadenav{
	position: fixed;
	height: 130px;
	top: 0;
	display: none;
	z-index: 9;
	.l-hero{
		display: none;
	}
}
.l-hero{
	background: #f4f4f4;
}
.hero-slider{
	display: none;
}
.l-property-area{
	@extend .clearfix;
	@extend %width-min;
	width: 100%;
	position: relative;
	background: $accent-color;
	z-index: 2;
}
.l-global-area{
	@extend .clearfix;
	@extend %width-min;
	position: relative;
	z-index: 2;
	background: #fff;
	height: 98px;
}
.logo-area{
	margin: 0;
	width: 320px;
	position: absolute;
	left: 0;
	top: 0;
	height: 98px;
	&:after{
		display: block;
		content: '';
		position: absolute;
		left: 50%;
		bottom: 0px;
		width:0%;
		height:4px;
		background: #dfdfdf;
		@extend %trans-ani;
	}
	&:hover{
		&:after{
			left: 0;
			width:100%;
			background: $accent-color;
		}
		a{
			color: $accent-color;
		}
	}
	&.is-active{
		&:after{
			left: 0;
			width:100%;
			background: $accent-color;
		}
	}
	h1{
		margin: 0;
	}
	a{
		display: block;
		width: 300px;
		height: 98px;
		background: url(#{$imgPath}layout/logo.png) no-repeat 10px 30px;
		background-size: 277px 36px;
		overflow: hidden;
		text-indent: -9999px;
		margin: 0;
	}

}
/*popup*///
.theme-popup{
	width: 100%;
	overflow: hidden;
	.l-global-area{
		border-bottom: 2px solid $accent-color;
		min-width: auto;
		height: 78px;
	}
	.logo-area{
		width: 240px;
		height: 78px;
		&:after{
			display: none;
		}
		a{
			width: 240px;
			height: 78px;
			background: url(#{$imgPath}layout/logo.png) no-repeat 10px 25px;
			background-size: 220px 29px;

		}
	}
	.close-btn{
		position: absolute;
		width: 140px;
		right: 20px;
		padding: 10px;
		border-radius: 4px;
		top: 15px;
		@extend %trans-ani;
		font-weight: bold;
		font-size: 12px;
		color: $accent-color;
		@extend .icon-icon-close;
		&:before {
			@extend %iconbase;
			@extend %trans-ani-cl;
			font-size: 12px;
			line-height: 12px;
			background: $accent-color;
			border-radius: 50%;
			display: inline-block;
			width: 20px;
			height: 20px;
			text-align: center;
			padding: 4px;
			color: #fff;
			margin-right: 10px;
		}
		&:hover{
			background: $accent-color;
			color: #fff;
			&:before{
				color: #fff;
			}
		}
	}
	.l-footer{
		min-width: auto;
	}
	.l-footer-attention{
		padding: 40px 20px;
	}
}
.l-global-navi{
	width: 100%;
	height: 98px;
	margin: 0;
	border-bottom: 4px solid #dfdfdf;
	ul{
		margin: 0 0 0 320px;
		padding: 0;
	}
	li{
		float: left;
		width: 20%;
		list-style: none;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 1px;
		position: relative;
		line-height: 100%;
		text-align: center;
		margin: 0;
		a{
			@extend %normal-link;
			color: $font_color;
			@extend %trans-ani;
			display: block;
			padding: 43px 0 42px;
			background: rgba(44,170,127,0);
		}
		&:before{
			display: block;
			content: '';
			position: absolute;
			width: 1px;
			height: 40px;
			left: 0;
			top: 29px;
			border-left: 1px solid #eeeeee;
		}
		&:after{
			display: block;
			content: '';
			position: absolute;
			left: 50%;
			bottom: 0px;
			width:0%;
			height:4px;
			background: #dfdfdf;
			@extend %trans-ani;
		}
		&:hover{
			&:after{
				left: 0;
				width:100%;
				background: $accent-color;
			}
			a{
				color: $accent-color;
				background: rgba(44,170,127,.1);
			}
		}
		&.is-active,&.current-page-ancestor,&.current_page_item{
			&:after{
				left: 0;
				width:100%;
				background: $accent-color;
			}
		}
	}
}
.l-user{
	font-size: 11px;
	margin: 10px;
	color: #fff;
	line-height: 100%;
}
.l-extra-navi{
	position: absolute;
	top: 0;
	right: 0;
	margin: 0px;
	line-height: 100%;
	li{
		font-size: 11px;
		line-height: 11px;
		margin: 0;
		padding: 0;
		display: inline-block;
		float: left;
		a{
			@extend %normal-link;
			@extend %trans-ani;
			display: inline-block;
			color: #fff;
			padding: 10px;
			background: rgba(255,255,255,0);
		}
		&.extra-attn{
			background: $point_color;
			border-right: 1px solid #ffb153;
		}
		&:hover{
			a{
				background: rgba(255,255,255,.4);
			}
		}
	}
}


.l-footer{
	@extend .clearfix;
	@extend %width-min;
	width: 100%;
	background: #fafafa;
}
.l-utility-navi{
	border-top: 1px solid #e9e9e9;
	text-align: center;
	padding: 40px 0;
	ul{
		padding: 0;
		margin: 0;
		li{
			@extend .icon-icon-arrow-right;
			list-style: none;
			display: inline-block;
			font-size: 12px;
			padding: 0 5px;
			&:before {
				@extend %iconbase;
				font-size: 10px;
				color: #ccc;
				padding: 0 10px;
			}
			a{
				@extend %normal-link;
				color: #888;
			}
		}
	}
}
.l-pagetop{
	@extend %trans-ani;
	position: fixed;
	bottom: 0px;
	right: 0px;
	width: 70px;
	height: 30px;
	z-index: 2;
	background: rgba(5,5,5,1);
	margin: 0;
	&:after {
		position: absolute;
		display: block;
		right: 0;
		top: 0;
		width: 70px;
		height: 30px;
		text-align: center;
		@extend %iconbase;
		content: $icon-icon-arrow-top;
		font-size: 15px;
		color: #fff;
		padding-top: 10px;
	}
	&:hover{
		background: rgba(44,170,127,1);
	}
}
.l-footer{
	@extend %width-min;
}
.l-footer-attention{
	text-align: center;
	padding: 40px 0;
	border-top: 1px solid #e9e9e9;
	color: #888;
	p{
		margin: 0;
	}
}
.l-copy{
	background: #232323;
	color: #afafaf;
	padding: 10px;
	margin: 0;
	font-size: 10px;
	line-height: 100%;
}
.l-hero{
	@extend %width-min;
	background: #f4f4f4;
	max-height:500px;
	overflow: hidden;
}
.hero-slider{
	max-width: 1500px;
	max-height:500px;
	margin: 0 auto!important;
	.slick-slide{
		overflow: hidden;
		position: relative;
		max-width: 500px;
		height: auto;
	}
	.hero-bg{
		position: relative;
	}
	.hero-image{
		@extend %trans-ani;
		position: absolute;
		width: 100%;
		max-width: 110%;
		top: 0%;
		left: 0%;
	}
	a:hover .hero-image{
		// opacity: .5;
		width: 110%;
		max-width: 110%;
		top: -5%;
		left: -5%;
	}
}
:root
%slick-btn{
	width: 40px;
	height: 40px;
	background: $accent-color;
	&:before{
		@extend %iconbase;
	}
}
.slick-prev{
	@extend %slick-btn;
	left: 0;
}
.slick-next{
	@extend %slick-btn;
	right: 0;
}
.slick-dots{
	margin: 0;
	padding: 0 0 0 5px;
}
.slick-dots li{
	width: 35px;
	height: 6px;
	top: -50px;
	button{
		width: 35px;
		&:before{
			width: 30px;
			height: 6px;
			background: #b5b5b5;
			content: '';
			opacity: .8;
		}
		&:hover:before,&:focus:before{
			background: $accent-color;
		}
	}
	&.slick-active button:before{
		background: $accent-color;
		opacity: 1;
	}
}

.l-cta{
	@extend %width-min;
	@extend .clearfix;
	background: #e9f6f2;
	padding: 40px 10px;
	height: 216px;
	text-align: center;
}
.cta-item-green{
	float: left;
	width: 50%;
	background: #e9f6f2;
	padding: 40px 10px;
	.cta-category-title{
		color: $accent-color;
		background: none;
		padding: 0;
		text-align: center;
	}
	&.covid19{
		>.cta-item-btn{
			width: 33%;
		}
	}
}

.cta-item-orange{
	float: left;
	width: 33%;
	background: #fff4e6;
	padding: 40px 10px;
	.flaticon-btn{
		@extend .flaticon-btn-orange;
	}
	.cta-category-title{
		color: $point_color;
		background: none;
		padding: 0;
		text-align: center;
	}
}
// オカネコリンクエリア
.cta-item-blue{
	float: left;
	width: 17%;
	background: #E5F0FC;
	padding: 40px 10px;
	.flaticon-btn{
		border:1px solid #B2D3F6;
		padding-top:2vw;
		display: table-cell;
		vertical-align: middle;
		.cta-title{
			color:#006EE4;
		}
		&:before{
			color:#006EE4;
		}
		&:hover{
			background:#006EE4;
			&:before{
				color:#fff;
			}
			.cta-title{
				color:#fff;
			}
		}
	}
	.cta-category-title{
		color: #006EE4;
		background: none;
		padding: 0;
		text-align: center;
	}
	.cta-item-btn{
		width:100%;
	}
}

.cta-item-btn{
	width: 24%;
	padding: 0 10px;
	display: inline-block;
	&:hover{
		.covid19-new {
			color: #fff;
			transition: 0.5s;
		}
		.icon-icon-video{
			&:before {
				content: url("../images/contents/top/icon-video-hover.svg");
				display: block;
				margin-top: -25px;
				transition: 0.5s;
			  }
		}
	}
}

.cta-harf{
	padding: 0;
	.cta-item-btn{
		@extend .col-lg-6;
		@extend .col-md-6;
		@extend .col-sm-6;
		@extend .col-xs-6;
	}
}
.cta-category-title{
	font-size: 18px;
}
.cta-title{
	height: 34px;
	margin-bottom: 10px;
	.covid19-new {
		font-size: x-small;
		display: block;
		margin-bottom: -6px;
		color: #eb2323;
		transition: 0.5s;
	  }
}
.cta-image{
	display: block;
	font-size: 120px;
	height: 60px;
	text-align: center;
	padding-bottom: 10px;
	&:before{
		position: relative;
		top: -30px;
	}
}
.cta-item-btn-single{
	margin: 0 auto;
	padding: 20px 0;
	width: 100%;
	position: relative;
	border-top: 2px solid #f4f4f4;
	i{
		position: absolute;
		left: 20px;
		top: 15px;
		&:before{
			font-size: 80px;
			color: #ff8f09;
		}
	}
	a{
		position: relative;
		@extend %trans-ani;
		display: block;
		width: 700px;
		margin: 0 auto;
		border: 3px solid #fff3e6;
		background: #fff;
		color: #ff8f09;
		font-weight: bold;
		font-size: 20px;
		border-radius: 4px;
		@extend %normal-link;
		padding: 35px 20px 35px 110px;
		&:hover{
			background: #ff8f09;
			border: 3px solid #ff8f09;
			color: #fff;
			i:before{
				color: #fff;
			}
			span{
				background: #fff;
				color: #ff8f09;
			}
		}
	}
	span{
		position: absolute;
		right: 30px;
		top: 30px;
	}
}
#bodyfade{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #fff;
	opacity: 1;
	z-index: 9999;
	display: none\9;
}
#searchfade{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: #fff;
	opacity: 1;
	z-index: 9999;
	display: none\9;
}
.spinner {
	width: 40px;
	height: 40px;
	background-color: $accent-color;

	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
	0% { -webkit-transform: perspective(120px) }
	50% { -webkit-transform: perspective(120px) rotateY(180deg) }
	100% { -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg) }
}

@keyframes sk-rotateplane {
	0% {
	transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
	} 50% {
	transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
	} 100% {
	transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}
.l-breadcrumbs-wrap{
	position: relative;
	background: #f8f8f8;
	@extend .clearfix;
}
.l-breadcrumbs-nav{
	@extend .clearfix;
	width: 100%;
	margin:0 auto;
	padding: 10px 20px;
	> li{
		float: left;
		list-style: none;
		text-align: left;
		margin: 0;
		font-size: 10px;
		a{
			@include linkcolor(#333);
			padding: 2px 10px 2px 0px;
			@extend %normal-link;
			&:after {
				@extend %iconbase;
				content: "\e610";
				font-size: 10px;
				color: $accent-color;
				padding: 0 0 0 10px;
			}
			&:hover{
				color: $accent-color;
			}
		}
		span{
			padding: 2px 10px 2px 0px;
		}
	}
}
.l-contents{
	@extend .clearfix;
}
.l-contents.theme-single{
	width: 100%;
	.l-main{
		padding: 30px 0;
		.contents-section{
			@extend %centerise;
			padding: 0 30px;
		}
	}
}
.l-contents.theme-multi{
	@extend %centerise;
	.l-main{
		@extend .col-lg-9;
		@extend .col-md-9;
		@extend .col-sm-9;
		@extend .col-xs-9;
		border-left: 2px solid #f8f8f8;
		margin-left: -2px;
		padding: 30px;
	}
	.l-side{
		@extend .col-lg-3;
		@extend .col-md-3;
		@extend .col-sm-3;
		@extend .col-xs-3;
		border-right: 2px solid #f8f8f8;
	}
}
.theme-popup{
	.l-main{
		padding: 30px 0;
		.contents-section{
			max-width:980px;
			padding: 0 30px;
		}
	}
}
.l-side > h4.category-title{
	font-size: 18px;
	color: $accent-color;
	margin: 35px 0 10px;
	padding: 0;
	border: 0;
	a{
		@extend %normal-link;
		@extend %trans-ani;
		display: block;
		color: $accent-color;
		padding: 10px;
		border-radius: 4px;
		&:hover{
			background: mix($accent-color,#fff,10%);
			color: $accent-color;
		}
	}
}
ul.l-local-navi{
	padding: 0;
	margin: 0 0 20px;
	> li{
		list-style: none;
		font-size: 18px;
		font-weight: bold;
		color: $accent-color;
		margin: 35px 0 10px;
		padding: 0;
		border: 0;
		a{
			@extend %normal-link;
			@extend %trans-ani;
			display: block;
			color: $accent-color;
			padding: 10px;
			border-radius: 4px;
			&:hover{
				background: mix($accent-color,#fff,10%);
				color: $accent-color;
			}
		}
		ul{
			margin: 0;
			padding: 0;
			> li{
				list-style: none;
				margin: 0;
				padding: 0;
				font-size: 13px;
				font-weight: bold;
				a{
					@include linkcolor($sub_color);
					@extend %normal-link;
					@extend %trans-ani;
					padding: 10px 10px 10px 25px;
					display: block;
					position: relative;
					border-radius: 4px;
					color: $accent-color;
					&:before {
						@extend %iconbase;
						color: $accent-color;
						content: "-";
						position: absolute;
						display: block;
						left: 8px;
						top: 15px;
					}
					&:hover{
						background: mix($accent-color,#fff,10%);
						color: $accent-color;
					}
					&.open:before{
						content: "-";
					}
				}
				&.current,&.menu-item-has-children{
					a:before {
						content: "+";
					}
					a.open:before{
						content: "-";
					}
				}
				&.local-category span{
					@extend %trans-ani;
					cursor: pointer;
					padding: 10px 10px 10px 25px;
					display: block;
					position: relative;
					border-radius: 4px;
					color: $accent-color;
					&:before {
						@extend %iconbase;
						color: $accent-color;
						content: "+";
						position: absolute;
						display: block;
						left: 8px;
						top: 15px;
					}
					&:hover{
						background: mix($accent-color,#fff,10%);
						color: $accent-color;
					}
				}
				&.active.local-category span{
					&:before {
						content: "-";
					}
				}
				&.local-category ul{
					display: none;
				}
				> ul{
					display: none;
					padding: 0;
					margin: 0 0 0 15px;
					font-size: 12px;
					font-weight: normal;
					> li{
						list-style: none;
						margin: 0;
						a{
							padding: 3px 10px 3px 10px;
							color: $font_color;
							&:before {
								display: none;
							}
						}
					}
				}
			}
		}
		.current-page-ancestor,.current-menu-ancestor,.current-menu-item,.current_page_item{
			> ul{
				display: block;
			}
		}
	}
}
.l-contents-header{
	@extend %width-min;
	height: 170px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	&:before{
		display: block;
		content: '';
		width: 50%;
		height: 170px;
		position: absolute;
		background: rgba(255,255,255,.85);

	}
	.category-title-wrap{
		text-align: center;
		padding: 60px 0;
	}
	.category-title{
		color: $accent-color;
		position: relative;
		width: 50%;
		// max-width: 490px;
		font-size: 20px;
		font-weight: bold;
	}
	.category-discription{
		position: relative;
		width: 50%;
		// max-width: 490px;
		font-size: 13px;
	}
	&.entry-title{
		background-image: url(#{$imgPath}contents/category-title-bg-entry.jpg);
	}
	&.lp-title{
		background-image: url(#{$imgPath}contents/category-title-bg-lp.jpg);
	}
}
.news-area{
	margin: 30px 0;
	padding: 0;
	li{
		list-style: none;
	}
	a{
		@extend %trans-ani;
		display: block;
		position: relative;
		padding: 10px;
		@extend %normal-link;
		color: $font_color;
		&:hover{
			background: mix(#fff,$accent-color,85);
		}
	}
	%news-span{
		display: block;
	}
	.news-date{
		@extend %news-span;
		position: absolute;
		width: 6.5em;
		left: 10px;
		top: 10px;
		color: $accent-color;
	}
	.news-text{
		@extend %news-span;
		padding-left: 6.5em;
		line-height: 1.6em;
	}
}
.pagenation{
	margin: 2em 10px;
	padding: 0 100px;
	text-align: center;
	position: relative;
	min-height: 30px;
	li{
		display: inline-block;
		list-style: none;
		width: 30px;
		height: 30px;
	}
	a{
		display: inline-block;
		@include linkcolor(#333);
		@extend %normal-link;
		width: 30px;
		height: 30px;
		border-radius: 4px;
	}
	.mp-prev{
		@extend .icon-icon-arrow-left;
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		padding: 0;
		overflow: hidden;
		&:before {
			position: absolute;
			@extend %iconbase;
			display: block;
			font-size: 10px;
			color: $accent-color;
			width: 30px;
			height: 30px;
			left: 0;
			bottom: 0;
			overflow: hidden;
			text-align: center;
			padding: 10px 0;
			background: #fff;
			@extend %trans-ani-bg;
		}
	}
	.mp-next{
		@extend .icon-icon-arrow-right;
		position: absolute;
		right: 0;
		top: 0;
		width: 30px;
		padding: 0;
		overflow: hidden;
		@media #{$small}{
			display: block;
			width: 100px;
			padding-right: 30px;
			text-align: right;
			font-size: 12px;
		}
		&:before {
			position: absolute;
			@extend %iconbase;
			display: block;
			font-size: 10px;
			color: $accent-color;
			width: 30px;
			height: 30px;
			right: 0;
			bottom: 0;
			overflow: hidden;
			text-align: center;
			padding: 10px 0;
			background: #fff;
			@extend %trans-ani-bg;
			@media #{$small}{
				padding-top: 8px;
			}
		}
	}
	.current_page{
		a{
			@include linkcolor(#fff);
			background: $accent-color;
		}
	}
	.current_page{
		color: $accent-color;
		font-weight: bold;
	}
}
.seminar-tab-list{
	@extend .clearfix;
	margin: 10px 0 24px;
	padding: 0;
	li{
		float: left;
		width: 14.28%;
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid mix(#fff,$accent-color,60);
		list-style: none;
		text-align: center;
		color: $accent-color;
		font-size: 11px;
		padding: 10px 0;
		font-weight: bold;
		background: #fff;
		line-height: 1.7em;
		cursor: pointer;
		@extend %trans-ani;
		&:hover{
			background: mix(#fff,$accent-color,85);
			color: #2caa7f;
			border-bottom: 1px solid #abddcc;
		}
		&.active{
			border: 1px solid #2caa7f;
			border-bottom: 0px;
			background: #2caa7f;
			color: #fff;
		}
	}
}
.seminar-item{
	@extend .clearfix;
	margin-bottom: 10px;
	a{
		@extend %trans-ani;
		@extend %normal-link;
		@extend .clearfix;
		padding: 5px;
		margin: -5px;
		display: block;
		color: $font-color;
		&:hover{
			background: mix(#fff,$accent-color,85);
		}
	}
}
.seminar-property{
	float: left;
	width: 20%;
	color: $accent-color;
	border: 1px solid mix(#fff,$accent-color,60);
	padding: 10px 0;
	margin: 0;
	span{
		display: block;
		text-align: center;
		line-height: 1.4em;
	}
	.seminar-place,.seminar-time{
		font-size: 12px;
	}
	.seminar-date{
		font-size: 18px;
	}
}
.seminar-description{
	@extend .col-lg-9;
	@extend .col-md-9;
	@extend .col-sm-9;
	@extend .col-xs-9;
	padding-left: 20px;
	h4.seminar-title{
		font-size: 14px;
		padding: 0;
		margin: 0;
		border-left: 0;
	}
	p.seminar-text{
		font-size: 13px;
		line-height: 1.6em;
		margin: 0;
	}
}
.no-online{
	li{
		width: 20% !important;
	}
}
.inner-local-navi{
	@extend .clearfix;
	padding: 10px;
	margin: 0;
	text-align: center;
	background: mix(#fff,$accent-color,90);
	border-radius: 4px;
	li{
		display: inline-block;
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center;
		padding: 0px 2%;
		font-weight: bold;
		a{
			@extend %normal-link;
			color: $accent-color;
			display: block;
			position: relative;
			&:before{
				@extend %trans-ani;
				content: '';
				display: block;
				position: absolute;
				width: 0%;
				left: 50%;
				height: 2px;
				bottom: 0px;
				background: $accent-color;
			}
			&:hover{
				&:before{
					width: 100%;
					left: 0;
				}
			}
		}
	}
}
.inner-local-navi-area{
	@extend .clearfix;
	width: 100%;
	background: mix(#fff,$accent-color,90);
	.inner-local-navi{
		@extend %width-fit;
		li{
			padding: 2% 3%;
		}
	}
}
@media print {
	.pickup-area,.inner-local-navi,.information-area,.external-ban-area,.attention-area,.theme-popup{
		max-width:980px!important;
		min-width: 980px!important;
	}
	.hero-slider{
		max-width:980px!important;
	}
	.l-fadenav.l-header,.l-pagetop,.tab-area.case-tab{
		display: none!important;
	}

}