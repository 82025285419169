@charset "utf-8";
h1.page-title{
	margin: 15px -30px 20px -32px;
	padding: 0 30px;
	border-left: 2px solid $accent-color;
	font-size: 24px;
}
.date{
	color: $accent-color;
	margin-top: -10px;
	margin-bottom: 30px;
}
.l-side + .l-main h1.page-title{
	margin: -15px -30px 20px -32px;
	padding: 30px;
}
/*contentsarea*/
h2{
	margin: 1.5em 0 0.8em 0;
	font-size: 18px;
	line-height: 1.8em;
	position: relative;
	border-bottom: 2px solid #f4f4f4;
	padding: 0 0 10px;
	color: $font_color;
	&:first-child{
		margin: 0 0 0.8em 0;
	}
	&:before{
		content: '';
		display: block;
		width: 20%;
		height: 2px;
		background: $accent-color;
		bottom: -2px;
		position: absolute;
	}
}
h3{
	margin: 1.5em 0 0.8em 0;
	font-size: 18px;
	line-height: 1.8em;
	color: $font_color;
	background: mix($accent-color,#fff,10%);
	border-radius: 4px;
	padding: 8px 16px;
	&:first-child{
		margin: 0 0 0.8em 0;
	}
	&.adjustHeight{
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
h4{
	line-height: 1.8em;
	border-left: 2px solid $accent-color;
	padding: 0 0 0 15px;
	margin: 1.5em 0 0.5em;
	font-size: 16px;
	font-weight: bold;
	color: $font_color;
	&:first-child{
		margin: 0 0 0.5em 0;
	}
	&.normal{
		border-left: 0px;
	}
}
h5{
	line-height: 1.8em;
	margin: 1.5em 0 0.5em;
	font-size: 16px;
	font-weight: bold;
	color: $accent-color;
	&:first-child{
		margin: 0 0 0.5em 0;
	}
}
h6{
	line-height: 1.8em;
	margin: 1.5em 0 0.5em;
	font-size: 14px;
	font-weight: bold;
	color: $font_color;
	&:first-child{
		margin: 0 0 0 0;
	}
}
blockquote{
	font-size: 1em;
}
ul{
	padding: 0 0 0 20px;
	margin-left: 0;
}
ul.text-link-ul{
	padding: 0;
	li{
		@extend %arrow-link;
		margin-bottom: 15px;
		position: relative;
		padding-left: 20px;
		&:before{
			position: absolute;
			left: 0px;
			top: 7px;
		}
	}
	ul{
		margin: 20px 0;
		padding-left: 0;
	}
}
ul.text-link-ul-v{
	@extend .clearfix;
	padding: 0;
	li{
		@extend %arrow-link;
		@extend .col-lg-4;
		@extend .col-md-4;
		@extend .col-sm-4;
		padding-left: 20px;
		&:before{
			position: absolute;
			left: 0px;
			top: 7px;
		}
	}
}
ul.text-link-ul-i{
	@extend .clearfix;
	padding: 0;
	li{
		@extend %arrow-link;
		display: inline-block;
		padding-left: 20px;
		padding-right: 20px;
		position: relative;
		&:before{
			position: absolute;
			left: 0px;
			top: 7px;
		}
	}
}
.asterisk-list{
	@extend .clearfix;
	padding: 0;
	counter-reset:asterisk;
	li{
		padding-left: 30px;
		list-style: none;
		position: relative;
		&:before {
			position: absolute;
			left: 0;
			counter-increment:asterisk;
			content: "※" counter(asterisk);
		}
	}
}
ul.asterisk-list{
	li:before {
		content: "※";
	}
}
.attention-list{
	@extend .clearfix;
	padding: 0;
	counter-reset:attention;
	li{
		padding-left: 30px;
		list-style: none;
		position: relative;
		&:before {
			position: absolute;
			left: 0;
			counter-increment:attention;
			content: "注" counter(attention) ")";
		}
	}
}
ul.attention-list{
	li:before {
		content: "(注)";
	}
}
ol{
	margin-left: 0;
	padding: 0 0 0 20px;
	&.style-decimal{
		& > li{
			list-style-type: decimal;
		}
	}
	&.style-lalfa{
		& > li{
			list-style-type: lower-alpha;
		}

	}
	&.style-lroman{
		& > li{
			list-style-type: lower-roman;
		}

	}
}
dl{
	dt{
		font-size: 16px;
		color: #555;
	}
	dd{
		margin-bottom: 1em;
	}
}
hr{
	border-top: 1px solid #e5e5e5;
	margin: 0;
}


.blank{
	background: url('#{$imgPath}window-icon.png') no-repeat right center;
	padding-right: 15px;
	margin-right: 5px;
}

table{
	margin:0 0 20px 0;
	width: 100%;
	background: #fff;
	caption{
		font-size: 14px;
		margin-bottom: 10px;
		font-weight: bold;
		text-align: left;
	}
	td{
		border:1px solid darken( #fff, 10% );
		padding:10px;
		line-height: lz(20);
		font-size: 1em;
		word-wrap: break-word;
		text-align: center;
	}
	th{
		border:1px solid darken( #fff, 10% );
		padding:10px;
		font-size: 1em;
		line-height: lz(20);
		background:darken( #fff, 5% );
		word-wrap: break-word;
	}
	ul{
	margin:10px 0;
	}
	li{
	font-size: 100%;
	}
	p{
	font-size: 100%;
	}
	&.noline{
	background: none;
	td{
		border:none;
		}
	th{
		border:none;
		background:none;
	}
	}
	&.bline{
		background: none;
		thead + tbody > tr:first-child{
			border-top: 1px solid mix($accent-color,#fff,70%);
		}
		tbody > tr >th + td{
			border-left: 1px dotted mix($accent-color,#fff,70%);
		}
		tr{
			border-bottom: 1px solid mix($accent-color,#fff,15%);
			&.border-bold{
				border-bottom: 2px solid mix($accent-color,#fff,15%);
			}
		}
		thead tr{
			border-bottom: 0px;
		}
		tbody tr:nth-child(2n+1){
			background: mix($accent-color,#fff,5%);
		}
		tbody > tr:first-child{
			border-top: 1px solid mix($accent-color,#fff,15%);
		}
		td{
			border:none;
		}
		th{
			border:none;
			background:none;
			color: $accent-color;
		}
	}
	.hd-bg{
		background: mix($sub-color,#fff,90);
		color: #fff;
	}
	.ac-bg{
		background: mix(#fff,$accent-color,80%);
	}
	.po-bg {
		background: #ffe9ce;
	}
	.bl-bg {
		background: #d6e1f7;
	}

}
.tablewrap{
	@media #{$small}{
		overflow: scroll;
	}
	table{
		@media #{$small}{
			width: auto;
			min-width: 440px;
		}
	}
}
.float-left{
	float: left;
	margin: 0 15px 15px 0;
}
.float-right{
	float: right;
	margin: 0 0 15px 15px;
}
.scrollarea{
	@media #{$middle}{
		overflow-y: hidden;
		overflow-x: scroll;
		-webkit-overflow-scrolling:touch;
		width: 94%;
		margin: 5% 2%;
		padding-right:5px;
		position: relative;
		&.noscroll{
			table{
				opacity: 0.4;
			}
		}
		.icon-scroll{
			position: absolute;
			display: block;
			content: '';
			width: 100%;
			height: 56px;
			left: 0;
			top: 0;
			opacity: 0.8;
			background: url('#{$imgPath}layout/icon_scrollarea.png') no-repeat center center;
			background-size: 56px 56px;
			z-index: 2;
		}
		table{
			min-width: 740px;
		}
	}
}
.block-table{
	td,th{
		@media #{$small}{
			display: block;
			width: 100%!important;
		}
	}
}
.arrow-link{
	@extend %arrow-link;
}
.image-wrap{
	text-align: center;
	margin-bottom: 15px;
	img{
		max-width:100%;
		height:auto;
	}
	span{
		display: block;
	}
}
.caption{
	text-align: center;
	margin: 10px auto;
}
.w-col1{
	width: 8.333333%!important;
}
.w-col2{
	width: 16.666666%!important;
}
.w-col3{
	width: 25%!important;
}
.w-col4{
	width: 33.333333%!important;
}
.w-col5{
	width: 41.666666%!important;
}
.w-col6{
	width: 50%!important;
}
.w-col7{
	width: 58.333333%!important;
}
.w-col8{
	width: 66.666666%!important;
}
.w-col9{
	width: 75%!important;
}
.w-col10{
	width: 83.333333%!important;
}
.w-col11{
	width: 91.666666%!important;
}
.w-col12{
	width: 100%!important;
}
a[target="_blank"]:after{
	@extend %iconbase;
	display: inline-block;
	content: $icon-opwindow;
	padding: 10px;
}
a[target="_blank"].blank-noicon:after{
	display: none;
}
/*flame*/
%flame-base{
	padding: 30px;
	border-radius: 4px;
	*:last-child{
		margin-bottom: 0;
	}
}
.flame-bg{
	@extend %flame-base;
	@extend .clearfix;
	background: mix(#fff,#999,90);
	&.flame-orange{
		background: mix(#fff,$point-color,90);
		h2,h3,h4{
			border-color: $point-color;
		}
		h5{
			color: $point-color;
		}
	}
	&.flame-green{
		background: mix(#fff,$accent-color,90);
		h2,h3,h4{
			border-color: $accent-color;
		}
		h5{
			color: $accent-color;
		}
	}
}
/*block-link*/
.block-link-item{
	p{
		display: none;
	}
	.block-link-title,.block-link-text,.image-wrap{
		display: block;
	}
}
.block-text-link{
	@extend .clearfix;
	padding: 10px 0;
	h2{
		text-align: center;
		border-bottom: none;
		padding: 0 0 20px 0;
		&:before{
			display: none;
		}
		span{
			display: inline-block;
			border-bottom: 2px solid $accent-color;
			padding-bottom: 10px;
		}
	}
}
.block-text-link-item{
	a{
		@extend %normal-link;
		@extend %trans-ani;
		@extend .icon-icon-arrow-right;
		display: block;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		padding: 20px;
		margin-bottom: 20px;
		color: $font_color;
		position: relative;
		overflow: hidden;
		&:before{
			@extend %iconbase;
			display: inline-block;
			position: absolute;
			right: 10px;
			font-size: 10px;
			bottom: 10px;
			color: $accent-color;
		}
		&:after{
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 2px;
			bottom: 0;
			left: 0;
			background: #c8eae0;
		}
		&:hover{
			background: $accent-color;
			border-color: $accent-color;
			color: #fff;
			h4,p,&:before{
				color: #fff;
			}
			&:after{
				background: $accent-color;
			}
		}
		&[target="_blank"]:after{
			padding: 0;
			content: ';'
		}
	}
	p{
		display: none;
		a{
			display: none;
		}
	}
	h4.block-text-link-title,.block-text-link-title{
		@extend %trans-ani;
		border-left: 0;
		padding: 0;
		color: $accent-color;
		margin-top: 0;
	}
	p.block-text-link-text,.block-text-link-text{
		display: block;
		@extend %trans-ani;
		color: $font_color;
		a{
			display: block;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}

/*is*/
.font-red{
	color: #cc0022;
}
.font-green{
	color: $accent-color;
}
.font-orange{
	color: $point-color;
}
.font-bold{
	font-weight: bold;
}
.text-left{
	text-align: left;
}
.text-right{
	text-align: right;
}
.text-center{
	text-align: center;
}
.text-ind1{
	display: block;
	padding-left: 2em;
}
.text-small{
	font-size: 90%;
}
.text-large{
	font-size: 110%;
}
.text-height15{
	line-height: 1.5;
}
.bg-frame{
	padding: 20px;
	border-radius:4px;
	background: #eee;
	background: rgba(255,239,239,0.5);
}
.bg-gframe{
	padding: 20px;
	border-radius:4px;
	background: #eee;
	background: rgba(238,238,238,0.5);
}
.smp-br{
	@media #{$small}{
		display: block;
	}
}
